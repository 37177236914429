@layer base {
	html,
	body,
	#root {
		@apply w-full min-h-full h-full overflow-x-hidden overflow-y-auto;
	}

	body {
		@apply font-primary text-base leading-relaxed;
	}

	a {
		color: #40a9ff;
	}
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
	padding: 8px 8px 8px 2.3em !important;
}

.ant-table-filter-trigger-container {
	right: unset !important;
	left: 0 !important;
}
