.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
	display: flex;
	align-items: center;

	td {
		padding: 16px;
	}

	.highlighted-number {
		font-weight: bold;
		font-size: 1.6rem;
	}
	.edit-btn,
	.delete-btn {
		display: none !important;
	}

	height: 48px !important;
	& > * {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		padding: 8px !important;
		border: none;
	}

	.drag-handle-col {
		width: 48px;
	}
	.actions-col {
		width: 96px;
	}

	&.micro-lesson-row {
		.subject-title-col {
			width: 148px;
		}
		.lvl-index-col {
			width: 84px;
			justify-content: center;
		}
		.lesson-number-col {
			width: 124px;
			justify-content: center;
		}
		.lesson-title-col {
			width: 198px;
		}
		.sequence-col {
			width: 184px;
			justify-content: center;
		}
		.qt-name-col {
			width: 98px;
			justify-content: center;
		}
		.question-text-col {
			flex: 1;
		}
		.status-col {
			width: 98px;
			justify-content: center;
		}
	}
	&.lesson-row {
		.subject-title-col {
			width: 178px;
		}
		.lvl-index-col {
			width: 110px;
			justify-content: center;
		}
		.lesson-number-col {
			width: 112px;
			justify-content: center;
		}
		.lesson-title-col {
			flex: 1;
		}
	}
	&.subject-row {
		.subject-index-col {
			width: 96px;
			justify-content: center;
		}
		.subject-title-col {
			width: 128px;
		}
		.subject-color-col {
			width: 68px;
			justify-content: center;
		}
		.subject-status-col {
			width: 92px;
			justify-content: center;
		}
		.subject-description-col {
			flex: 1;
		}
	}
}
.drag-handle {
	visibility: hidden;
	pointer-events: none;
}
.drag-handle-col .drag-handle {
	visibility: visible;
	pointer-events: all;
}
