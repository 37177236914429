/* components */
@layer components {
	.edit-btn {
		@apply rounded-sm border-transparent focus:ring focus:ring-gray-700 hover:border-gray-700 bg-gray-100 text-gray-900 flex items-center justify-center #{!important};
	}

	.delete-btn {
		@apply rounded-sm border-transparent focus:ring focus:ring-red-400 hover:border-red-400 bg-gray-100 text-red-500 flex items-center justify-center #{!important};
	}

	.fullscreen-ant-modal {
		@apply h-full;
		:global {
			.ant-modal {
				@apply w-full m-0 p-0 top-0 inline #{!important};

				.ant-modal-content {
					@apply h-full #{!important};

					.ant-modal-header .ant-modal-title {
						@apply leading-none;
					}
					.ant-modal-body {
						height: calc(100% - 110px);
						@apply overflow-y-scroll;
					}
				}
			}
		}
	}
}

.color-picker-popover .ant-popover-inner-content {
	padding: 0;
}
.dashboard-card {
	min-width: 174px;
	max-width: 198px;
	min-height: 222px;

	&:nth-child(3n + 1) {
		.top-section {
			background-color: #34d399;
		}
		.cta-btn {
			background-color: darken(#34d399, 5%);
		}
	}
	&:nth-child(3n + 2) {
		.top-section {
			background-color: #60a5fa;
		}
		.cta-btn {
			background-color: darken(#60a5fa, 5%);
		}
	}
	&:nth-child(3n + 3) {
		.top-section {
			background-color: #a78bfa;
			min-height: 144px;
		}
		.cta-btn {
			background-color: darken(#a78bfa, 5%);
		}
	}
}

.ant-pagination-simple-pager > input {
	width: 2.25rem !important;
	padding: 0 !important;
}

.ant-table-wrapper {
	height: 100%;

	.ant-spin-nested-loading {
		height: 100%;

		.ant-spin-container {
			height: 100%;
			display: flex;
			flex-flow: column nowrap;

			.ant-table {
				flex: auto;
				overflow: hidden;

				.ant-table-container {
					height: 100%;
					display: flex;
					flex-flow: column nowrap;

					.ant-table-content {
						height: 100%;
						.ant-table-header {
							flex: none;
						}

						.ant-table-body {
							flex: auto;
							overflow: scroll;
							height: 100%;
						}
					}
				}
			}

			.ant-table-pagination {
				flex: none;
				margin: 0.5rem 0;
			}
		}
	}
}

.ant-btn-loading-icon,
.ant-message-custom-content,
.ant-switch-inner,
.ant-form-item-children-icon,
.ant-select-selection-item-remove {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.ant-modal {
	max-width: 960px !important;
}
.ant-modal-body {
	overflow-x: hidden;
}
.ant-upload-list-item-info {
	.ant-upload-span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ant-upload-list-item-name {
		white-space: normal;
	}
	.ant-upload-text-icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
